
import { Actions } from '@/store/modules/ResourceModule';
import { reactive, ref, toRefs } from '@vue/reactivity';
import { computed, onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import axios from 'axios';
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const modelUrl = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const cropper = ref<any>(null);
    const store = useStore();

    const state = reactive({
      loading: false,
      disableSubmit: true,
      isCroppedMode: false,
      model: null as any,
      modelSrc: null as any,
      previews: null as any,
      downImg: null as any,
      cropperOption: {
        img: null as any,
        size: 1,
        full: true,
        outputType: 'png',
        canMove: true,
        fixedBox: false,
        original: true,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 1000,
        autoCropHeight: Math.floor((9 / 21) * 1000),
        centerBox: false,
        high: true,
        max: 99999,
        ratio: 21 / 9,
        initRatio: 21 / 9,
      },
    });

    onMounted(() => {
      state.cropperOption.img = modelUrl;
    });

    return {
      cropper,
      ...toRefs(state),
      finishAndUploadToS3() {
        cropper.value.getCropBlob(async (file) => {
          try {
            const { uploadUrl, resourceUrl } = await store.dispatch(
              Actions.GENERATE_UPLOAD_URL
            );

            await axios.put(`${uploadUrl}`, file, {
              headers: {
                'Content-Type': file.type,
                noAuth: true,
              },
            });

            state.cropperOption.img = resourceUrl;
            modelUrl.value = resourceUrl;
            state.isCroppedMode = false;
          } catch (error) {
            Swal.fire({
              text: 'Sorry, looks like there are some errors detected, please try again.',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok, got it!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        });
      },
      startCrop() {
        // start
        cropper.value.startCrop();
      },
      stopCrop() {
        //  stop
        cropper.value.stopCrop();
      },
      clearCrop() {
        // clear
        cropper.value.clearCrop();
      },
      refreshCrop() {
        // clear
        cropper.value.refresh();
      },
      changeScale(num) {
        num = num || 1;
        cropper.value.changeScale(num);
      },
      rotateLeft() {
        cropper.value.rotateLeft();
      },
      rotateRight() {
        cropper.value.rotateRight();
      },
      finish(type) {
        // 输出
        // var test = window.open('about:blank')
        // test.document.body.innerHTML = '图片生成中..'
        if (type === 'blob') {
          cropper.value.getCropBlob((data) => {
            console.log(data);
            var img = window.URL.createObjectURL(data);
            state.model = true;
            state.modelSrc = img;
          });
        } else {
          cropper.value.getCropData((data) => {
            state.model = true;
            console.log({ data });
            state.modelSrc = data;
          });
        }
      },
      download(type) {
        // event.preventDefault()
        var aLink = document.createElement('a');
        aLink.download = 'demo';
        if (type === 'blob') {
          cropper.value.getCropBlob((data) => {
            state.downImg = window.URL.createObjectURL(data);
            aLink.href = window.URL.createObjectURL(data);
            aLink.click();
          });
        } else {
          cropper.value.getCropData((data) => {
            state.downImg = data;
            aLink.href = data;
            aLink.click();
          });
        }
      },
      selectImage(e) {
        var file = e.target.files[0];
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
          return false;
        }
        var reader = new FileReader();
        reader.onload = (e: any) => {
          let data;
          if (typeof e.target.result === 'object') {
            // 把Array Buffer转化为blob 如果是base64不需要
            data = window.URL.createObjectURL(new Blob([e.target.result]));
          } else {
            data = e.target.result;
          }

          state.cropperOption.img = data;
          state.disableSubmit = false;
        };
        // 转化为base64
        // reader.readAsDataURL(file)
        // 转化为blob
        reader.readAsArrayBuffer(file);
      },
      imgLoad(msg) {
        if (msg === 'error') {
          state.disableSubmit = true;
        }
      },
    };
  },
};
