
import {
  Actions,
  PromotionBannerPayload,
} from '@/store/modules/PromotionBannerModule';
import {
  Actions as PromotionAction,
  Promotion,
} from '@/store/modules/PromotionModule';

import { onMounted, reactive, ref, toRefs, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import IconPallette from '@/components/icon/IconPallette.vue';
import {
  PromotionTypeEnum,
  AmountTypeEnum,
  PaymentTypes,
  ServicesTypes,
} from '../../core/data/constance';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useStore } from 'vuex';

import { mergeDateTime, numberFormat } from '@/utils/helper/format';
import Cropper from './components/Cropper.vue';

export default {
  name: 'AddEditPromotion',
  components: {
    IconPallette,
    Cropper,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref(null);

    const state = reactive({
      loaded: false,
      id: '' as string,
      couponTotal: 0,
      isAddCoupon: false,
      promotions: [] as Promotion[],
      formData: {
        title: null,
        image: null,
        start: null,
        end: null,
        isActive: false,
        description: null,
        startDatePath: undefined,
        startTimePath: undefined,
        endDatePath: undefined,
        endTimePath: undefined,
      } as PromotionBannerPayload,
    });

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: '',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    watch(
      () => [state.formData.start],
      ([date]) => {
        state.formData.start = date;

        if (
          state.formData.end &&
          state.formData.start &&
          state.formData.start > state.formData.end
        ) {
          state.formData.end = state.formData.start;
        }
      }
    );

    watch(
      () => route.params.id,
      async (id) => {
        if (id) {
          state.id = `${id}`;
          const banner = await store.dispatch(
            `${Actions.GET_PROMOTION_BANNER_BY_ID}`,
            id
          );

          state.formData = banner;
        }
      }
    );

    const fetchAllPromotion = () => {
      return store.dispatch(PromotionAction.FETCH_ALL_PROMOTION);
    };

    onMounted(async () => {
      const { id } = route.params;

      if (id) {
        state.id = `${id}`;

        try {
          const banner = await store.dispatch(
            `${Actions.GET_PROMOTION_BANNER_BY_ID}`,
            id
          );

          state.formData = banner;
        } catch (error: any) {
          console.error({ message: error.message });
        }
      }

      state.promotions = await fetchAllPromotion();

      state.loaded = true;
    });

    const elRules = reactive({
      title: [
        {
          required: true,
          message: 'Please input title',
          trigger: 'change',
        },
        {
          pattern: /^[ก-ฮเ-ไA-Za-z0-9][ก-๙A-Za-z0-9.-\\ ]*$/,
          trigger: 'change',
          message: 'Invalid title',
        },
      ],
      start: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a date',
          trigger: 'change',
        },
      ],
      end: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a date',
          trigger: 'change',
        },
      ],
    });

    watch(
      () => [state.formData.startDatePath],
      ([date]) => {
        state.formData.startTimePath = date;

        if (
          state.formData.endDatePath &&
          state.formData.startDatePath &&
          state.formData.startDatePath > state.formData.endDatePath
        ) {
          state.formData.endDatePath = state.formData.startDatePath;
        }
      }
    );

    watch(
      () => [state.formData.endDatePath],
      async ([date]) => {
        state.formData.endTimePath = date;
      }
    );

    watch(
      () => [state.formData.startTimePath],
      async ([time]) => {
        const startDate = mergeDateTime(
          state.formData.startDatePath || new Date(0).toISOString(),
          time || state.formData.startDatePath || new Date(0).toISOString()
        );

        state.formData.start = startDate ? startDate.toUTCString() : null;
      }
    );

    watch(
      () => [state.formData.endTimePath],
      async ([time]) => {
        const endDate = mergeDateTime(
          state.formData.endDatePath || new Date(0).toISOString(),
          time || state.formData.endDatePath || new Date(0).toISOString()
        );
        state.formData.end = endDate ? endDate.toUTCString() : null;
      }
    );

    return {
      formRef,
      ...toRefs(state),
      AmountTypeEnum,
      PromotionTypeEnum,
      PaymentTypes,
      ServicesTypes,
      numberFormat,

      async handleChangeStatus(e) {
        e.preventDefault();
        if (state.id) {
          try {
            await store.dispatch(Actions.UPDATE_PROMOTION_BANNER_STATUS, {
              id: state.id,
              isActive: state.formData.isActive,
            });

            await Toast.fire({
              icon: 'success',
              title: 'Success',
            });
          } catch (error) {
            console.error({ error });

            await Toast.fire({
              icon: 'error',
              title: 'Failed',
            });
          }
        }
      },
      elRules,
      async handleSubmit(formEl) {
        if (!formEl) return;

        formEl.validate(async (valid, fields) => {
          if (valid) {
            try {
              if (state.id) {
                await store.dispatch(`${Actions.UPDATE_PROMOTION_BANNER}`, {
                  id: state.id,
                  payload: state.formData,
                });
              } else {
                await store.dispatch(
                  `${Actions.CREATE_PROMOTION_BANNER}`,
                  state.formData
                );
              }

              Swal.fire({
                text: 'Form has been successfully submitted!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                router.push({ name: 'promotionBannerList' });
              });
            } catch (error) {
              console.error({ error });

              Swal.fire({
                text: 'Sorry, looks like there are some errors detected, please try again.',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            }
          } else {
            const errs: string[] = [];
            Object.values(fields).forEach(async ([errField]: any) => {
              errs.push(`${errField.field}: ${errField.message}`);
            });

            await Toast.fire({
              icon: 'error',
              title: `Invalid form data`,
            });
          }
        });
        return;
      },
    };
  },
};
